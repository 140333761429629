/**
 *  Created by pw on 2020/11/12 8:51 下午.
 */
import { __awaiter, __generator } from "tslib";
import { app } from '@ekuaibao/whispered';
import { get, set } from 'lodash';
export function fetchStaffs(response, staffPath) {
    if (staffPath === void 0) { staffPath = 'flowPlanConfig.editorId'; }
    return __awaiter(this, void 0, Promise, function () {
        var _a, items, total, staffIdMap, ids, staffs, staffMap;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!response) {
                        return [2 /*return*/, response];
                    }
                    _a = response.items, items = _a === void 0 ? [] : _a, total = response.total;
                    staffIdMap = items.reduce(function (idMap, item) {
                        var staffId = get(item, staffPath);
                        if (staffId) {
                            idMap[staffId] = item;
                        }
                        return idMap;
                    }, {});
                    ids = Object.keys(staffIdMap);
                    if (!(ids === null || ids === void 0 ? void 0 : ids.length)) {
                        return [2 /*return*/, response];
                    }
                    return [4 /*yield*/, app.invokeService('@common:get:staff:by:ids', { ids: ids })];
                case 1:
                    staffs = (_b.sent()).items;
                    staffMap = staffs.reduce(function (result, staff) {
                        result[staff.id] = staff;
                        return result;
                    }, {});
                    items = items.map(function (item) {
                        var staffId = get(item, staffPath);
                        set(item, staffPath, staffMap[staffId]);
                        return item;
                    });
                    return [2 /*return*/, {
                            total: total,
                            items: items
                        }];
            }
        });
    });
}
