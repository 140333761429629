export const ID = '@custom-flow'

export default {
  ID: ID,
  GET_EBOT_CONFIG: `${ID}/GET_EBOT_CONFIG`,
  EBOT_UPDATE_CONFIG: `${ID}/EBOT_UPDATE_CONFIG`,
  EBOT_SAVE_CONFIG: `${ID}/EBOT_SAVE_CONFIG`,
  GET_PLAN_CONFIG_LIST: `${ID}/GET_PLAN_CONFIG_LIST`,
  CREATE_PLAN_CONFIG: `${ID}/CREATE_PLAN_CONFIG`,
  UPDATE_PLAN_CONFIG: `${ID}/UPDATE_PLAN_CONFIG`,
  DELETE_PLAN_CONFIG: `${ID}/DELETE_PLAN_CONFIG`,
  GET_SHARE_SHOW: `${ID}/GET_SHARE_SHOW`,

  NEW_PLAN_CONFIG_AT_LOCAL: `${ID}/NEW_PLAN_CONFIG_AT_LOCAL`,
  DEL_PLAN_CONFIG_AT_LOCAL: `${ID}/DEL_PLAN_CONFIG_AT_LOCAL`,
  EDIT_PLAN_CONFIG_AT_LOCAL: `${ID}/EDIT_PLAN_CONFIG_AT_LOCAL`,

  GET_RULE_LIST: `${ID}/GET_RULE_LIST`,
  GET_CONDITION_LIST: `${ID}/GET_CONDITION_LIST`,

  ADD_CONDITION: `${ID}/ADD_CONDITION`,
  DELETE_CONDITION: `${ID}/DELETE_CONDITION`,
  UPDATE_CONDITION: `${ID}/UPDATE_CONDITION`,
  GET_ROLE_CONFIG: `${ID}/GET_ROLE_CONFIG`,
  SET_CURRENT_FLOW_FOR_CONFIG: `${ID}/SET_CURRENT_FLOW_FOR_CONFIG`,
  UPDATE_SET_SIGATURE:`${ID}/UPDATE_SET_SIGATURE`,
  GET_TRAVEL_TYPES: `${ID}/GET_TRAVEL_TYPES`,
  GET_BILL_BY_CODE: `${ID}/GET_BILL_BY_CODE`,
  GET_APPROVE_MATRIX_LIST: `${ID}/GET_APPROVE_MATRIX_LIST`
}
