export function fieldFilter(field, isApproval) {
  let arr = field.filter(filterType).filter(filterRef)
  let newarr
  if (isApproval) {
    newarr = [
      { active: true, name: 'flowId', label: i18n.get('单据ID(flowId)'), dataType: { type: 'text' } },
      { active: true, name: 'nodeId', label: i18n.get('节点ID(nodeId)'), dataType: { type: 'text' } }
    ].concat(arr)
  } else {
    newarr = [
      { active: true, name: 'action', label: i18n.get('通知类型'), dataType: { type: 'text' } },
      { active: true, name: 'actionName', label: i18n.get('通知类型名称'), dataType: { type: 'text' } },
      { active: true, name: 'userInfo', label: i18n.get('接收人信息'), dataType: { type: 'userInfo' } },
      { active: true, name: 'flowId', label: i18n.get('单据ID(flowId)'), dataType: { type: 'text' } },
      { active: true, name: 'nodeId', label: i18n.get('节点ID(nodeId)'), dataType: { type: 'text' } }
    ].concat(arr)
  }
  return newarr
}

function filterType(item) {
  const {
    dataType: { type }
  } = item
  return ['text', 'date', 'dateRange', 'number', 'money', 'boolean', 'ref'].includes(type)
}

function filterRef(item) {
  const {
    dataType: { type, entity }
  } = item

  if (type === 'ref') {
    const array = [
      'organization.Staff',
      'organization.Department',
      'basedata.Dimension',
      // 'flow.FeeType',
      'datalink.DataLinkEntity',
      'datalink.DataLinkEntityList',
      'pay.PayeeInfo',
      'basedata.city'
    ]
    return !!array.find(k => !!~entity.indexOf(k))
  }
  return true
}

const fieldMap = {
  text: '',
  date: 1484498318241,
  dateRange: {
    from: 1484498318241,
    to: 1484498318398
  },
  number: 23.4,
  money: {
    currency: 'CNY', //本位币币种
    amount: '10.00', //本位币金额
    /* 如果金额是本位币，则无下列字段 */
    foreignCurrency: 'USD', //外币币种
    foreignAmount: '68.80', //外币金额
    exchangeRate: '6.88' //汇率 = 外币金额 ÷ 本位币金额
  },
  boolean: 'true|false',
  Staff: {
    id: i18n.get('员工id'), //这里的id是易快报的员工id
    name: i18n.get('姓名')
  },
  dataLink: {
    id: 'x2y34',
    name: 'xxxx',
    code: '1234'
  },
  Department: {
    id: i18n.get('档案值id'),
    path: 'AAA/BBB/CCC',
    name: 'CCC',
    code: '0902'
  },
  payeeInfo: {
    sort: 'BANK|ALIPAY|OVERSEABANK|OTHER',
    type: 'PERSONAL',
    name: i18n.get('易快报'),
    nameSpell: 'YIKUAIBAO',
    cardNo: '1234567890123456789',
    bank: i18n.get('招商银行'),
    province: i18n.get('江苏省'),
    city: i18n.get('南京市'),
    branch: i18n.get('招商银行股份有限公司南京中央路支行'),
    certificateType: '03',
    certificateNo: '13012345678',
    bankLinkNo: '308777006211'
  },
  city: {
    id: '5001',
    code: 'AD',
    name: i18n.get('安道尔'),
    enName: 'Andorra',
    country: i18n.get('国外'),
    fullName: i18n.get('安道尔'),
    nameSpell: 'andaoer'
  },
  userInfo: {
    id: i18n.get('员工id'),
    name: i18n.get('张三'),
    cellphone: '13111111111',
    email: '123@qq.com'
  },
  active: true,
  ownerId: { id: i18n.get('员工id'), name: i18n.get('张三') },
  visibility: {
    staffs: [{ id: i18n.get('员工id'), name: i18n.get('张三') }],
    roles: [{ id: i18n.get('角色id'), name: i18n.get('高级管理员') }],
    departments: [{ id: i18n.get('部门id'), name: i18n.get('研发部') }],
    fullVisible: false
  },
  useCount: 1
}
export function filterBody(arr) {
  const result = {}
  arr.forEach(v => {
    let key = v.dataType.type
    if (v.dataType.type === 'ref') {
      if (v.dataType.entity.split('.')[1] === 'Staff') {
        key = 'Staff'
      } else if (v.dataType.entity.split('.')[1] === 'DataLinkEntity') {
        key = 'dataLink'
      } else if (v.dataType.entity.split('.')[1] === 'PayeeInfo') {
        key = 'payeeInfo'
      } else if (v.dataType.entity.split('.')[1] === 'city') {
        key = 'city'
      } else {
        key = 'Department'
      }
    }
    result[key === 'payeeInfo' ? 'payeeInfo' : v.name] = fieldMap[key]
  })
  return result
}

export function filterDataLinkBody(arr) {
  const result = {}
  arr.forEach(v => {
    let key = v.type
    if (v.type === 'ref') {
      if (v.entity.split('.')[1] === 'Staff') {
        key = 'Staff'
      } else if (v.entity.split('.')[1] === 'DataLinkEntity') {
        key = 'dataLink'
      } else if (v.entity.split('.')[1] === 'PayeeInfo') {
        key = 'payeeInfo'
      } else if (v.entity.split('.')[1] === 'city') {
        key = 'city'
      } else {
        key = 'Department'
      }
    } else if (v.type === 'autoNumber') {
      key = 'text'
    } else if (v.type === 'switcher') {
      key = 'boolean'
    } else if (v.type === 'text') {
      if (v.name === 'visibility') {
        key = 'visibility'
      } else {
        key = 'text'
      }
    }
    result[v.name] = fieldMap[key]
  })
  return result
}
