// @i18n-ignore-all
import { app as api } from '@ekuaibao/whispered'
import {
  setCurrentFlowIdForConfig,
  getRoleConfig,
  getOrgShareData,
  getEbotDataLinkConfig,
  getFlowPlanSetting,
  getConditionList,
  getPlanConfigs,
  getPlanConfigsName,
  getMatrixList
} from './custom-flow-action'
import loadable from '@loadable/component'

export const Universal_Unique_Key = 'customFlow.pc'

export default [
  {
    id: '@custom-flow',
    reducer: () => import('./custom-flow-reducer'),
    path: '/custom-flow',
    ref: '/',
    onload: () => import('./custom-flow-view'),
    'get:ebot:datalink:config': id => api.dispatch(getEbotDataLinkConfig(id)),
    'set:current:flow:for:config': id => api.dispatch(setCurrentFlowIdForConfig(id)),
    'get:role:config': id => api.dispatch(getRoleConfig(id)),
    'get:org:share:data': params => getOrgShareData(params),
    'get:flow:plan:setting': () => getFlowPlanSetting(),
    'get:plan:configs:name': () => getPlanConfigsName(),
    'get:plan:configs': params => api.dispatch(getPlanConfigs(params)),
    'get:condition:list'(params) {
      return api.dispatch(getConditionList(params))
    },
    'get:approve:matrix:list'(data, qs) {
      return api.dispatch(getMatrixList(data, qs))
    }
  },
  {
    id: '@custom-flow',
    path: '/custom-flow/:flowId',
    ref: '/',
    exact: true,
    onload: () => import('./custom-flow-view')
  },
  {
    point: '@@layers',
    prefix: '@custom-flow',
    onload: () => import('./layers')
  },
  {
    id: '@approve-matrix',
    path: '/approve-matrix',
    ref: '/',
    exact: true,
    onload: () => import('./matrix/approve-matrix-view')
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'custom-flow',
        pId: 'enterprise-manage',
        permissions: ['SYS_ADMIN'],
        weight: 7,
        label: '审批流',
        href: '/custom-flow',
        icon: 'enterprise-custom-flow',
        regexpHref: true
      }
    ]
  },

  {
    point: '@@components',
    namespace: '@custom-flow',
    onload: () => [
      {
        key: 'ConditionalSelect',
        component: () => import('./elements/conditional/conditional-select')
      },
      {
        key: 'ConditionalInput',
        component: () => import('./elements/conditional/conditionaI-input')
      }
    ]
  },

  {
    resource: '@custom-flow',
    value: {
      ['elements/conditional/conditionaI-input']: loadable(() =>
        import('./elements/conditional/conditionaI-input')
      ),
      ['elements/conditional/conditional-select']: loadable(() =>
        import('./elements/conditional/conditional-select')
      ),
      ['elements/ebot/fieldFilter']: require('./elements/ebot/fieldFilter'),
      ['elements/plan-detail/FlowNodeRole']: loadable(() =>
        import('./elements/plan-detail/FlowNodeRole')
      ),
      ['elements/plan-nodes']: loadable(() => import('./elements/plan-nodes'))
    }
  }
]
