import { app as api } from '@ekuaibao/whispered'
import key from './key'
import { Resource, Fetch } from '@ekuaibao/fetch'

const planConfigs = new Resource('/api/v1/flow/planConfigs')
const rules = new Resource('/api/v1/rule')
const datalinkEbot = new Resource('/api/v1/datalinkEbot')
const role = new Resource('/api/v1/flow/planConfigs/roles')
const organizationShare = new Resource('/api/authority/v2/action/share')
const customCondition = new Resource('/api/v1/flow')
const searchByCode = new Resource('/api/flow/v2/flows/searchByCode')
const orgShare = new Resource('/api/authority/v2/action/share/show')
const dimensions = new Resource('/api/v1/basedata/dimensions')
const approveOwners = new Resource('/api/v1/group/center/permission')
const crossCorpSigners = new Resource('/api/v1/flow/planConfigs')
const matrixAction = new Resource('/api/v1/matrix')
const matrixActionV2 = new Resource('/api/v2/matrix')

import { fetchStaffs } from './util/fetchUtil'

export function getPlanConfigs({ orgId }) {
  const data = {
    orgId,
    join: `id,organizationData,/authority/v2/action/share/show/$SPECIFICATION`
  }
  return {
    type: key.GET_PLAN_CONFIG_LIST,
    payload: planConfigs.GET('/actives', data)
  }
}

export function getPlanConfigsName() {
  return planConfigs.GET('/actives/name')
}

export function getShareShow(id) {
  return {
    type: key.GET_SHARE_SHOW,
    payload: organizationShare.GET(`/show/$model/${id}`, { model: 'SPECIFICATION' })
  }
}

export function ebotDataLinkSaveConfig(data) {
  return {
    type: key.EBOT_SAVE_CONFIG,
    payload: datalinkEbot.POST('/saveConfig', data)
  }
}

export function ebotDataLinkUpdateConfig(data) {
  return {
    type: key.EBOT_UPDATE_CONFIG,
    payload: datalinkEbot.PUT('/ebotSetting/$id ', data)
  }
}

export function getEbotDataLinkConfig(params) {
  return {
    type: key.GET_EBOT_CONFIG,
    payload: datalinkEbot.GET('/getDataLinkEbotConfig/$id ', params)
  }
}

export function createPlanConfig(data, originId, orgId) {
  return {
    type: key.CREATE_PLAN_CONFIG,
    payload: planConfigs.POST('/', data, { orgId }),
    originId
  }
}

export function editPlanConfig(data, orgId) {
  const join = `id,organizationData,/authority/v2/action/share/show/$SPECIFICATION`
  return {
    type: key.UPDATE_PLAN_CONFIG,
    payload: planConfigs.PUT('/$id', data, { orgId, join })
  }
}

export function deletePlanConfig(data, orgId) {
  return {
    type: key.DELETE_PLAN_CONFIG,
    payload: planConfigs.DELETE('/$id', data, { orgId }),
    done() {
      api.dispatch(getPlanConfigs({ orgId }))
    }
  }
}

export function newPlanConfigAtLocal(data) {
  return {
    type: key.NEW_PLAN_CONFIG_AT_LOCAL,
    payload: data
  }
}

export function delPlanConfigAtLocal(id) {
  return {
    type: key.DEL_PLAN_CONFIG_AT_LOCAL,
    payload: id
  }
}

export function editPlanConfigAtLocal(data) {
  return {
    type: key.EDIT_PLAN_CONFIG_AT_LOCAL,
    payload: data
  }
}

export function getRuleList() {
  let params = { type: 'COMPARE' }
  return {
    type: key.GET_RULE_LIST,
    payload: rules.GET('/rules', params)
  }
}

// 自定义条件列表
export function getConditionList(params) {
  return {
    type: key.GET_CONDITION_LIST,
    payload: customCondition.GET('/customCondition', params)
  }
}

export function addCondition(params) {
  const { data, orgId } = params
  return {
    type: key.ADD_CONDITION,
    payload: customCondition.POST('/customCondition', data, { orgId })
  }
}

export function deleteCondition(params) {
  return {
    type: key.DELETE_CONDITION,
    payload: customCondition.DELETE('/customCondition/$id', params)
  }
}

export function upDateCondition(params) {
  const { data, orgId } = params
  return {
    type: key.UPDATE_CONDITION,
    payload: customCondition.PUT('/customCondition/$id', data, { orgId })
  }
}

export function getRoleConfig(orgId) {
  return {
    type: key.GET_ROLE_CONFIG,
    payload: role.GET('', { orgId })
  }
}

//用reducer实现联查流程
export function setCurrentFlowIdForConfig(id) {
  return {
    type: key.SET_CURRENT_FLOW_FOR_CONFIG,
    payload: id
  }
}

// 获取自定义条件的组织共享信息
export function getOrgShareData(params) {
  return orgShare.GET('/$model/[ids]', params)
}

// 获取 eBot 创建自定义档案，组织共享编辑的档案列表
export function getEbotDimensionData(orgId) {
  return dimensions.GET('/can/edit/list', { orgId })
}

export function getFlowPlanSetting() {
  return planConfigs.GET('/setting')
}

export function updateFlowSetting(params) {
  return planConfigs.POST('/setting/$id', params)
}

//批量设置影像签名
export function updateSetSignature(data) {
  return {
    type: key.UPDATE_SET_SIGATURE,
    payload: planConfigs.POST('/batch/setUseSignature/$data', { data })
  }
}

// 协同审批节点选择审批人
export function getApproveOwners() {
  return approveOwners.GET('/approveOwners')
}
export function getCrossCorpSigners(params) {
  return crossCorpSigners.GET('/crossCorpSigners', params)
}

export function getFlowPlanConfigVersioned(param) {
  return planConfigs.POST('/getFlowPlanVersioned/$id', param).then(fetchStaffs)
}

export function getFlowCustomConditionVersioned(param) {
  const { orgId, flowCustomConditionId } = param
  return customCondition
    .POST(
      `/customCondition/versioned?orgId=${orgId}&flowCustomConditionId=${flowCustomConditionId}`,
      param
    )
    .then(res => fetchStaffs(res, 'flowCustomCondition.editorId'))
}

export function batchDeleteFlow(params) {
  const { ids, orgId } = params
  return planConfigs.DELETE('/batchDelete/[ids]', { ids }, { orgId })
}

export function getTravelTypes() {
  return {
    type: key.GET_TRAVEL_TYPES,
    payload: planConfigs.GET('/travelTypes')
  }
}

export function getBillByCode(code) {
  return searchByCode.POST(
    '',
    {
      filterBy: `(form.code == \"${code}\" && ((state != \"draft\") && (state != \"rejected\")))`,
      limit: { start: 0, count: 1 }
    },
    {
      join: `form.specificationId,specificationId,/form/v1/specificationVersions`
    }
  )
}

export function searchMatrixList(data, qs) {
  return matrixAction.POST('/search/matrixbase', data, qs)
}

export function searchMatrixById(params) {
  return matrixAction.POST('/search/matrixrule/$id', params)
}
//添加审批矩阵只有名字
export function addMatrix(data) {
  return matrixAction.POST('/addBase', data)
}
//更新矩阵的字段配置及名称
export function updateMatrixBase(parmas) {
  return matrixAction.PUT('/updateBase', parmas)
}
//添加矩阵规则
export function addMatrixRule(data) {
  return matrixAction.POST(`/addRule/$matrixId`, data)
}
//删除规则
export function deleteRule(data) {
  return Fetch.DELETE(`/api/v1/matrix/deleteRule`, null, { body: data })
}
//批量修改规则
export function updateRuleBatch(data) {
  return matrixAction.POST(`/updateRuleBatch`, data)
}
//获取导入状态
export function getImportStatus(baseId) {
  return matrixActionV2.GET(`/excel/getImportStatus/$baseId`, { baseId })
}
//关闭导入提示---导入成功和失败的时候
export function closeFailMessage(baseId) {
  return matrixActionV2.GET(`/excel/closeFailMessage/$baseId`, { baseId })
}

export function diabledActiveMatrix(parmas) {
  return matrixAction.PUT('/active/$id', null, parmas)
}

export function updateMatrix(params) {
  return params?.matrixBase?.id?.length
    ? matrixAction.PUT('/update', params)
    : matrixAction.POST('/add', params)
}

export function downloadImportTemplate(params) {
  return Fetch.POST(
    '/api/v2/matrix/excel/export/template',
    {},
    { body: params, headers: { accept: 'application/octet-stream' }, isBlob: true }
  )
}

export function downloadMatrixData(params) {
  return Fetch.POST(
    '/api/v2/matrix/excel/export',
    {},
    { body: params, headers: { accept: 'application/octet-stream' }, isBlob: true }
  )
}

export function getMatrixList(data, qs) {
  return {
    type: key.GET_APPROVE_MATRIX_LIST,
    payload: matrixAction.POST('/search/matrixbase', data, qs)
  }
}
